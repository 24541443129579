<template>
    <form-modal
        ref="formModal"
        :v="$v.modalBusinessUnit"
        :title="i18nTitleModal"
        :handle-hide="cleanModal"
        :in-editing="isEdit"
        size="lg"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nBusinessUnit"
                    :operation="'edited'"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                :title="getI18n(i18nKey, 'TITLES.business_unit')"
                                @onClick="isEdit = true"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nName"
                            label-for="modal-name"
                            class="label-pdv"
                        >
                            <b-form-input
                                id="modal-name"
                                v-model="modalBusinessUnit.name"
                                class="input-pdv-blue"
                                name="name"
                                aria-describedby="input-1-live-feedback"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nContractValue"
                            label-for="modal-contract-value"
                            class="label-pdv"
                        >
                            <money
                                id="modal-contract-value"
                                v-model="modalBusinessUnit.contract_value"
                                v-bind="money"
                                name="contract_value"
                                class="form-control input-pdv-blue"
                                aria-describedby="input-1-live-feedback"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nContractTime"
                            label-for="modal-contract-time"
                            class="label-pdv"
                        >
                            <b-form-input
                                id="modal-contract-time"
                                v-model="modalBusinessUnit.contract_time"
                                name="contract_time"
                                aria-describedby="input-1-live-feedback"
                                type="number"
                                min="0"
                                class="input-pdv-blue"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <topic-header
                    :caption="i18nServingCostModuleRules"
                    icon="fe-grid"
                />
                <b-row>
                    <!-- <b-col md="3">
                        <b-form-group
                            :label="i18nInside"
                            label-for="modal-cost-serving-rule-in-min"
                            class="required label-pdv"
                        >
                            <b-input-group class="input-group-pdv-blue">
                                <template v-slot:append>
                                    <b-input-group-text>
                                        <i class="fe-percent" />
                                    </b-input-group-text>
                                </template>
                                <b-form-input
                                    id="modal-cost-serving-rule-in-min"
                                    v-model.number="modalBusinessUnit.cost_serving_rule_in_min"
                                    class="input-pdv-blue"
                                    name="cost-serving-rule-in-min"
                                    aria-describedby="input-1-live-feedback"
                                    type="number"
                                    :disabled="!isEdit || submitLoading"
                                    min="0"
                                    :state="validateField('cost_serving_rule_in_min', 'modalBusinessUnit')"
                                    @blur="$v.modalBusinessUnit.cost_serving_rule_in_min.$touch;"
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col> -->
                    <b-col md="3">
                        <b-form-group
                            :label="i18nAlert"
                            label-for="modal-cost-serving-rule-alert-min"
                            class="required label-pdv"
                        >
                            <b-input-group class="input-group-pdv-blue">
                                <template v-slot:append>
                                    <b-input-group-text>
                                        <i class="fe-percent" />
                                    </b-input-group-text>
                                </template>
                                <b-form-input
                                    id="modal-cost-serving-rule-alert-min"
                                    v-model.number="modalBusinessUnit.cost_serving_rule_alert_min"
                                    class="input-pdv-blue"
                                    name="cost-serving-rule-alert-min"
                                    aria-describedby="input-1-live-feedback"
                                    type="number"
                                    :disabled="!isEdit || submitLoading"
                                    min="0"
                                    :state="validateField('cost_serving_rule_alert_min', 'modalBusinessUnit')"
                                    @blur="$v.modalBusinessUnit.cost_serving_rule_alert_min.$touch;"
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group
                            :label="i18nOutside"
                            label-for="modal-cost-serving-rule-out-min"
                            class="required label-pdv"
                        >
                            <b-input-group class="input-group-pdv-blue">
                                <template v-slot:append>
                                    <b-input-group-text>
                                        <i class="fe-percent" />
                                    </b-input-group-text>
                                </template>
                                <b-form-input
                                    id="modal-cost-serving-rule-out-min"
                                    v-model.number="modalBusinessUnit.cost_serving_rule_out_min"
                                    class="input-pdv-blue"
                                    name="cost-serving-rule-out-min"
                                    aria-describedby="input-1-live-feedback"
                                    type="number"
                                    :disabled="!isEdit || submitLoading"
                                    min="0"
                                    :state="validateField('cost_serving_rule_out_min', 'modalBusinessUnit')"
                                    @blur="$v.modalBusinessUnit.cost_serving_rule_out_min.$touch;"
                                />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChainBillingApportionmentType"
                            class="required label-pdv"
                            label-for="modal-chain-billing-apportionment-type"
                        >
                            <translated-multiselect
                                id="modal-chain-billing-apportionment-type"
                                v-model="typeSelected"
                                :options="chainBillingApportionmentTypes"
                                :select-class="validationClass($v.modalBusinessUnit.chain_billing_apportionment_type)"
                                :disabled="!isEdit || submitLoading"
                                @input="changeType"

                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>
<script>
import { Money } from 'v-money'

import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import {
    required,
    decimal,
    minValue,
    minLength,
} from 'vuelidate/lib/validators'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import chainBillingApportionmentTypes from '@src/mixins/intelligence/costOfServing/chainBillingApportionmentTypes'
import BusinessUnitsService from '@src/services/BusinessUnitsService'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import TopicHeader from '@src/components/TopicHeader.vue'
import translatedMultiselect from '@components/translatedMultiselect'
import { i18n } from '@src/i18n'

const i18nCommon = 'COMMON'
const i18nKey = 'BUSINESS_UNITS'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        Money,
        CancelButton,
        SaveButton,
        EditLabelButton,
        TopicHeader,
        translatedMultiselect,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        chainBillingApportionmentTypes
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    name: '',
                    contract_value: 0,
                    contract_time: 0,
                    add_all_users: false,
                    add_all_pos: false,
                    cost_serving_rule_in_min: 0,
                    cost_serving_rule_alert_min: 0,
                    cost_serving_rule_out_min: 0,
                    chain_billing_apportionment_type: null,
                    users: [],
                    point_of_sales: [],
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalBusinessUnit: this.modal,
            isEdit: false,
            submitLoading: false,
            typeSelected: null,
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: i18n.t('CURRENCY.suffix'),
                precision: 2,
                masked: false,
            },
        }
    },
    validations: {
        modalBusinessUnit: {
            cost_serving_rule_in_min: {
                required,
                decimal,
                minValue: minValue(0),
            },
            cost_serving_rule_alert_min: {
                required,
                decimal,
                minValue: minValue(0),
                afterMinValue(value, model) {
                    return value > model.cost_serving_rule_in_min
                },
            },
            cost_serving_rule_out_min: {
                required,
                decimal,
                minValue: minValue(0),
                afterAlertValue(value, model) {
                    return value > model.cost_serving_rule_alert_min
                },
            },
            chain_billing_apportionment_type: {
                required,
                minLength: minLength(1),
            }
        },
    },
    computed: {
        i18nTitleModal() {
            if (this.isEdit) {
                return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nBusinessUnit}`
            }
            return this.modalBusinessUnit.name
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nKey, 'TITLES.business_unit')
        },
        i18nContractValue() {
            return this.getI18n(i18nKey, 'TITLES.contract_value')
        },
        i18nContractTime() {
            return this.getI18n(i18nKey, 'TITLES.contract_time')
        },
        i18nServingCostModuleRules() {
            return this.getI18n(i18nKey, 'TITLES.serving_cost_module_rules')
        },
        i18nInside() {
            return this.getI18n(i18nCommon, 'inside')
        },
        i18nAlert() {
            return this.getI18n(i18nCommon, 'alert')
        },
        i18nOutside() {
            return this.getI18n(i18nCommon, 'outside')
        },
        i18nChainBillingApportionmentType() {
            return this.getI18n(i18nKey, 'TITLES.chain_billing_apportionment_type')
        }
    },
    methods: {
        changeType(option) {
            this.modalBusinessUnit.chain_billing_apportionment_type = option ? option.value : null
            this.$v.modalBusinessUnit.chain_billing_apportionment_type.$touch()
        },

        showModal() {
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.modalBusinessUnit = this.modal
            this.typeSelected = this.chainBillingApportionmentTypes
                .find((t) => t.value === this.modal.chain_billing_apportionment_type) || null
            this.$refs.formModal.show()
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        cleanModal() {
            this.replaceModalData({
                id: '',
                name: '',
                contract_value: 0,
                contract_time: 0,
                add_all_users: false,
                add_all_pos: false,
                cost_serving_rule_in_min: 0,
                cost_serving_rule_alert_min: 0,
                cost_serving_rule_out_min: 0,
                chain_billing_apportionment_type: null,
                users: [],
                point_of_sales: [],
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalBusinessUnit, data)
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modalBusinessUnit.$touch()
            if (!this.$v.modalBusinessUnit.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const businessUnitData = {
                cost_serving_rule_in_min: this.modalBusinessUnit.cost_serving_rule_in_min,
                cost_serving_rule_alert_min: this.modalBusinessUnit.cost_serving_rule_alert_min,
                cost_serving_rule_out_min: this.modalBusinessUnit.cost_serving_rule_out_min,
                chain_billing_apportionment_type: this.modalBusinessUnit.chain_billing_apportionment_type,
            }

            const response = await BusinessUnitsService.updateIntelligence(this.modalBusinessUnit.id, businessUnitData)
                .then((response) => {
                    this.replaceModalData(response.data.data)
                    return response
                })
                .catch((error) => {
                    this.negativeFeedback()
                    return error
                })

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nBusinessUnit, 'edited')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
    },
}
</script>
