<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import chainBillingApportionmentTypes from '@src/mixins/intelligence/costOfServing/chainBillingApportionmentTypes'

const i18nCommon = 'COMMON'
const i18nKey = 'BUSINESS_UNITS'

export default {
    mixins: [
        vuetableFormatter,
        chainBillingApportionmentTypes
    ],
    data() {
        return {
            businessUnitFields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'contract_value',
                    title: this.getI18n(i18nKey, 'TITLES.contract_value'),
                    sortField: 'contract_value',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'contract_time',
                    title: this.getI18n(i18nKey, 'TITLES.contract_time'),
                    sortField: 'contract_time',
                },
                // {
                //     name: 'cost_serving_rule_in_min',
                //     title: this.getI18n(i18nCommon, 'inside'),
                //     sortField: 'cost_serving_rule_in_min',
                //     formatter: this.formatPercentage,
                // },
                {
                    name: 'cost_serving_rule_alert_min',
                    title: this.getI18n(i18nCommon, 'alert'),
                    sortField: 'cost_serving_rule_alert_min',
                    formatter: this.formatPercentage,
                },
                {
                    name: 'cost_serving_rule_out_min',
                    title: this.getI18n(i18nCommon, 'outside'),
                    sortField: 'cost_serving_rule_out_min',
                    formatter: this.formatPercentage,
                },
                {
                    name: 'chain_billing_apportionment_type',
                    title: this.getI18n(i18nKey, 'TITLES.chain_billing_apportionment_type'),
                    sortField: 'chain_billing_apportionment_type',
                    formatter: this.chainBillingApportionmentTypeToText,
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
