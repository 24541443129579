<script>
export default {
    methods: {
        existsInTable(name) {
            const dataInTable = this.$refs.vuetable.tableData.find(
                (row) => row.name === this.name
            )
            if (dataInTable && dataInTable.id > 0) {
                return true
            }
            return false
        },
    },
    convertNumToTime(number) {
        // Check sign of given number
        let sign = number >= 0 ? 1 : -1

        // Set positive value of number of sign negative
        number = number * sign

        // Separate the int from the decimal part
        const hour = Math.floor(number)
        let decpart = number - hour

        const min = 1 / 60
        // Round to nearest minute
        decpart = min * Math.round(decpart / min)

        let minute = Math.floor(decpart * 60) + ''

        // Add padding if need
        if (minute.length < 2) {
            minute = '0' + minute
        }

        // Add Sign in final result
        sign = sign === 1 ? '' : '-'

        // Concate hours and minutes
        return sign + hour + ':' + minute
    },
}
</script>
