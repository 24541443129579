import BaseService from '@src/services/BaseService'

class BusinessUnitsService extends BaseService {
    constructor() {
        super('business-units')
    }

    updateIntelligence(id, params) {
        return this.axios.post(`${this.getUrl()}/${id}/update-intelligence`, params)
    }
}

export default new BusinessUnitsService()
